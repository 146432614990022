import { Injectable } from '@angular/core';
import { ToastyService, ToastOptions, ToastData } from 'ng2-toasty';

@Injectable()
export class ToastsService {

    constructor(
        private toastyService: ToastyService
    ) {
    }

    position = 'bottom-right';
    title: string;
    msg: string;
    showClose = true;
    theme = 'bootstrap';
    type = 'default';
    closeOther = false;

    public success(message: string, title?: string) {
        if (!title) {
            // translate
        }
        this.addToast(
            {
                title: title, 
                msg: message, 
                showClose: true, 
                timeout: 5000, 
                theme: 'default', 
                type: 'success', 
                position: 'bottom-right', 
                closeOther: false
            }
        );
    }

    public error(message: string, title?: string) {
        if (!title) {
            // translate
        }
        this.addToast(
            {
                title: title, 
                msg: message, 
                showClose: true, 
                timeout: 5000, 
                theme: 'default', 
                type: 'error', 
                position: 'bottom-right', 
                closeOther: false
            }
        );
    }

    public warning(message: string, title?: string) {
        if (!title) {
            // translate
        }
        this.addToast(
            {
                title: title, 
                msg: message, 
                showClose: true, 
                timeout: 5000, 
                theme: 'default', 
                type: 'warning', 
                position: 'bottom-right', 
                closeOther: false
            }
        );
    }

    private addToast(options) {
        if (options.closeOther) {
            this.toastyService.clearAll();
        }
        this.position = options.position ? options.position : this.position;
        const toastOptions: ToastOptions = {
            title: options.title,
            msg: options.msg,
            showClose: options.showClose,
            timeout: options.timeout,
            theme: options.theme,
            onAdd: (toast: ToastData) => {
                /* added */
            },
            onRemove: (toast: ToastData) => {
                /* removed */
            }
        };

        switch (options.type) {
            case 'default': this.toastyService.default(toastOptions); break;
            case 'info': this.toastyService.info(toastOptions); break;
            case 'success': this.toastyService.success(toastOptions); break;
            case 'wait': this.toastyService.wait(toastOptions); break;
            case 'error': this.toastyService.error(toastOptions); break;
            case 'warning': this.toastyService.warning(toastOptions); break;
        }
    }
}