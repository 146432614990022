/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.ngstyle";
import * as i1 from "./spinkit-css/sk-line-material.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./spinner.component";
import * as i5 from "@angular/router";
var styles_SpinnerComponent = [i0.styles, i1.styles];
var RenderType_SpinnerComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
function View_SpinnerComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "sk-line-material"]], [[2, "colored", null]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "div", [["class", "sk-child sk-bounce1"]], [[4, "background-color", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.backgroundColor; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.backgroundColor; _ck(_v, 1, 0, currVal_1); }); }
function View_SpinnerComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "div", [["id", "http-loader"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "loader-bg"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_2)), i2.ɵdid(3, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.spinner === _co.Spinkit.skLine); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SpinnerComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_SpinnerComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSpinnerVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SpinnerComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i2.ɵdid(1, 180224, null, 0, i4.SpinnerComponent, [i5.Router, i3.DOCUMENT], null, null)], null, null); }
var SpinnerComponentNgFactory = i2.ɵccf("app-spinner", i4.SpinnerComponent, View_SpinnerComponent_Host_0, { backgroundColor: "backgroundColor", spinner: "spinner" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
