import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { Secrets } from 'src/environments/secrets';
import * as url from 'url';
import { Md5 } from 'ts-md5/dist/md5';

url.URLSearchParams = URLSearchParams; // some workaround

@Injectable()
export class TPayPaymentLinkGenerator {

    // based on https://docs.tpay.com/

    private merchantId: string = Secrets.TPAY_MERCHANT_ID;
    private merchantSecurityCode: string = Secrets.TPAY_MERCHANT_SECURITY_CODE;

    private tpayUrl: string = environment.paymentGatewaytUrl;
    private description: string = environment.paymentDescription;

    // optional
    private optionalCallbackUrl: string = environment.paymentCallbackUrl;
    private optionalReturnUrl: string = environment.paymentReturnUrl;
    private optionalReturnErrorUrl: string = environment.paymentReturnErrorUrl;

    create(amount: string, userId: string, futureSubscriptionId: string, customerEmail: string): string {
        var query = new URLSearchParams();
        query.append('id', this.merchantId);
        query.append('description', this.description);
        query.append('amount', amount);
        var crc = userId + '+' + futureSubscriptionId;
        crc = crc.replace('-', '');
        query.append('crc', crc); // will be used for callback checking
        query.append('md5sum', this.calculateMd5(amount, crc));
        query.append('email', customerEmail);

        if (this.optionalCallbackUrl && this.optionalCallbackUrl.length > 0) {
            query.append('result_url', this.optionalCallbackUrl);
        }
        if (this.optionalReturnUrl && this.optionalReturnUrl.length > 0) {
            query.append('return_url', this.optionalReturnUrl);
        }
        if (this.optionalReturnErrorUrl && this.optionalReturnErrorUrl.length > 0) {
            query.append('return_error_url', this.optionalReturnErrorUrl);
        }

        return this.tpayUrl + '?' + query.toString();
    }

    private calculateMd5(amount: string, crc: string): string {
        return Md5.hashStr(this.merchantId.concat(amount, crc, this.merchantSecurityCode)).toString();
    }
}