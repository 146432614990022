import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse, Subscription, InvoiceData } from './model';

@Injectable()
export class PremiumSubscriptionService {

    private apiBaseUrl: string = environment.apiUrl;

    constructor(
        private http: HttpClient
    ) {
    }

    getFutureSubscription(): Observable<ApiResponse<Subscription>> {
        return this.http.get<ApiResponse<Subscription>>(this.apiBaseUrl + 'api/subscription/future');
    }

    startFutureSubscription(): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/subscription/future', null);
    }

    getHistory(): Observable<ApiResponse<Subscription[]>> {
        return this.http.get<ApiResponse<Subscription[]>>(this.apiBaseUrl + 'api/subscription/history');
    }

    setInvoiceData(invoice: InvoiceData): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/subscription/invoice', invoice);
    }
}